import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullname: "",
  phone_number: "",
  email: "",
  notice_period: "",
  exp_years: 0,
  exp_months: 0,
  linkedin: "",
  is_fresher: false,
  organisation: [
    {
      organisationName: "",
      startDate: null,
      endDate: null,
      roleNResponsibility: ""
    }
  ],
  skills: [],
}

export const formSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    setFullname: (state, action) => {
      state.fullname = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phone_number = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setNoticePeriod: (state, action) => {
      state.notice_period = action.payload;
    },
    setExpYears: (state, action) => {
      state.exp_years = action.payload;
    },
    setExpMonths: (state, action) => {
      state.exp_months = action.payload;
    },
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
    setLinkedin: (state, action) => {
      state.linkedin = action.payload;
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
  },
});

export const {
  setFullname,
  setPhoneNumber,
  setEmail,
  setExpMonths,
  setExpYears,
  setLinkedin,
  setNoticePeriod,
  setOrganisation,
  setSkills,
} = formSlice.actions;
export default formSlice.reducer;
