import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/comman.scss";
import "./assets/scss/custom.scss";

import { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

const Layout = lazy(()=> import("./pages/Layout"));
const HomePage = lazy(()=> import("./pages/HomePage"));
const JobDetailPage = lazy(()=> import("./pages/JobDetailPage"));
const JobFormPage = lazy(()=> import("./pages/JobFormPage"));
const CompanyPage = lazy(()=> import("./pages/CompanyPage"));
const Error404 = lazy(()=> import("./pages/Error404"));
const ScrollToTop = lazy(()=> import("./components/shared/ScrolltoTop"));

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Add the event listener for the window load event
    window.addEventListener('load', ()=>setLoading(false));
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('load', ()=>setLoading(false));
  }, []);
  return (
    loading ? 
      <div className="center">
        <span className="loader"></span>
      </div>
      :
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense >
            <ScrollToTop/>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="/:clid/:customerName/:jobTitle/:jobCode" element={<JobDetailPage />} />
                <Route path="/:clid" element={<CompanyPage />} />
                <Route path="jobform" element={<JobFormPage />} />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
  );
}

export default App;
